import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import AuthService from "../../services/auth.services";
import "./navbar.css";

function Navbar({ isToken, setIsToken, isLoading }) {
  const currentUser = AuthService.getCurrentUser();
  const [progress, setProgress] = useState(0); //LINEAR PROGRESS

  useEffect(() => {
    if (currentUser) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [currentUser, setIsToken]);

  useEffect(() => {
    if (isLoading) {
      setProgress(0);
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 30) {
          return 0;
        }
        return Math.min(oldProgress + 3, 100);
      });
    }, 1350); // Ulangi tarik data per 15 detik
    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  return (
    <>
      {currentUser && (
        <div className="linear-progress-nav">
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
      <div className="navbar-person">
        <Link to="/">
          <div className="suar-logo">
            <img src="assets/suar.png" alt="logo" width="80px" />
          </div>
        </Link>
      </div>
    </>
  );
}

export default Navbar;
