import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./app.css";
import Footer from "./components/footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";

function App() {
  const [isToken, setIsToken] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <>
      <Router>
        <>
          <Navbar setIsToken={setIsToken} isToken={isToken} />
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </>
      </Router>
    </>
  );
}

export default App;
