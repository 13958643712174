import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import CardCalon from "../components/CardCalon";
import instance from "../services/axios";

function Home() {
  const detik = 15;
  const [dataDefault, setDataDefault] = useState([]);
  const [medsos, setMedsos] = useState("article");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchDataDefault = async () => {
      const dataDefault = await instance.get("api/dmonitor");
      setDataDefault(dataDefault.data);
      setIsLoading(false);
    };

    fetchDataDefault();
    const timer = setInterval(() => {
      setIsLoading(true);
      fetchDataDefault();
    }, detik * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [setIsLoading]);

  return (
    <>
      <div className="container-person">
        <div className="wrapper-person">
          {dataDefault.length > 0 ? (
            dataDefault.map((data, idx) => (
              <CardCalon
                key={idx}
                isLoading={isLoading}
                setMedsos={setMedsos}
                medsos={medsos}
                dataCalon={data?.user_data}
                image={`data:image/jpeg;base64, ${data?.profile_link}`}
                nama={data?.profile_title}
              />
            ))
          ) : (
            <div className="loading-circle">
              <CircularProgress size={100} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
